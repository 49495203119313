<template lang="pug">
  div(v-if="ready")
    div.all-notifications-list-wrapper
      div.all-notifications-container
          notifications-results(v-if="confirmationNotifications.length" v-for="(item, index) in confirmationNotifications"
            :key="index"
            :item="item")
          div.empty-list(v-if="!confirmationNotifications.length") {{'ui.labels.list_is_empty' | translate}}
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import NotificationsResults from '@/components/common/notifications/NotificationsResults'
import { NOTIFICATIONS_TYPES } from '@/util/constants'

export default {
  name: 'OpenConfirmations',

  components: {
    NotificationsResults
  },

  async mounted () {
    this.init()
  },

  data: () => ({
    ready: false
  }),

  methods: {
    ...mapActions('notifications', ['onNotificationsSubscribe']),

    async  init () {
      if (!this.notifications.length) {
        await this.onNotificationsSubscribe()
      }
      this.ready = true
    }

  },

  computed: {
    ...mapGetters('notifications', ['notifications']),

    confirmationNotifications () {
      return this.notifications.filter(el => el.typeId === NOTIFICATIONS_TYPES.BADGE_VALIDATION_REQUEST)
    }
  }

}

</script>

<style lang="scss" scoped>

</style>
